.hideTokensHeader {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: var(--space-1);
  padding: 5px var(--space-2);
  background-color: var(--color-background-light);
  border-radius: 6px;
  min-width: 185px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: var(--space-3);
}

.cancelButton {
  padding: 4px 10px;
}

.applyButton {
  padding: 6px var(--space-3);
}
