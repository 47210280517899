.filterWrapper {
  position: sticky;
  z-index: 2;
  top: 144px;
  border-width: 1px;
}

.filterWrapperModal {
  position: sticky;
  z-index: 2;
  top: 0;
  border-width: 1px;
}

@media (max-width: 600px) {
  .filterWrapper {
    position: relative;
    z-index: 0;
    top: 0;
  }
}
