.container {
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  border-radius: 0 !important;
  background-color: var(--color-background-lightcolor);
  border-bottom: 1px solid var(--color-border-light);
}

.paddingleftelement {
  padding-left: 4px !important;
}

.element {
  padding: 0 var(--space-2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menuButton,
.logo {
  flex: 1;
  border: none;
  align-items: flex-start;
}

.logo svg {
  width: auto;
  display: block;
  color: var(--color-logo-main);
  height: 20px;
}

.logo {
  padding: 16px 16px 16px 24px;
}

.menuButton {
  display: none;
}

.networkSelector {
  padding-right: 0;
  padding-left: 0;
  border-right: none;
}

.connectWallet {
  flex-shrink: 0;
}

@media (max-width: 900px) {
  .logo {
    display: none;
  }

  .menuButton {
    display: flex;
  }
}

@media (max-width: 600px) {
  .element {
    padding: 0 var(--space-1);
  }

  .menuButton {
    padding-left: var(--space-2);
  }

  .networkSelector {
    padding-right: 0;
  }

  .hideMobile {
    display: none;
  }

  .hideSidebarMobile {
    visibility: hidden;
  }
}

.container {
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 0 !important;
  background-color: var(--color-background-lightcolor);
  border-bottom: 1px solid var(--color-border-light);
}

.element {
  padding: 0 var(--space-2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menuButton {
  flex: 1;
  border: none;
  align-items: flex-start;
}

.logo svg {
  width: auto;
  display: block;
  color: var(--color-logo-main);
  height: 20px;
}

.logo {
  border: none;
  align-items: flex-start;
  padding: 16px 16px 16px 24px;
}

.menuButton {
  display: none;
}

.ButtonNav {
  font-size: 16px;
  color: #757575;
  padding: 8px 16px;
  margin: 0 4px;
  font-weight: normal;
  border-radius: 10px;
  gap: 1px;
}

.ButtonNav:hover {
  background: #5050500f;
}

.ButtonNavSelected {
  background: var(--color-background-papercolor);
  font-size: 16px;
  color: currentColor;
  padding: 8px 16px;
  margin: 0 4px;
  font-weight: normal;
  border-radius: 10px;
  filter: drop-shadow(0 2px 2px #00000010);
  gap: 1px;
}

.ButtonNavSelected:hover {
  background: var(--color-background-papercolor);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0.5rem 1.5rem;
}

.networkSelector {
  padding-right: 0;
  padding-left: 0;
  border-right: none;
}

.connectWallet {
  flex-shrink: 0;
}

@media (max-width: 900px) {
  .logo {
    display: none;
  }

  .menuButton {
    display: flex;
  }

  .hideMobile {
    display: none;
  }

  .hideSidebarMobile {
    visibility: hidden;
  }
}
