.cardHeader {
  padding-bottom: 0px;
}

.title {
  width: fit-content;
  padding: 4px var(--space-1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.titleIcon {
  font-size: 12px;
}

.tipsList :global .MuiCardContent-root {
  padding: 0;
}

.tipAccordion {
  background-color: inherit;
  border: none;
}

.tipAccordion :global .MuiAccordionSummary-root:hover {
  background: inherit;
}

.tipAccordion :global .Mui-expanded.MuiAccordionSummary-root {
  background: inherit;
  font-weight: bold;
}

.tipAccordion :global .MuiAccordionDetails-root {
  padding-top: 0;
}
