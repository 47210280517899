.container {
  position: relative;
}

.container button[type='submit'] {
  position: absolute;
  bottom: var(--space-3);
  right: var(--space-3);
  z-index: 1;
}

.stepIndicator {
  padding-right: var(--space-1);
}
