.guardDisplay {
  background-color: var(--color-secondary-background);
  border: 1px solid var(--color-secondary-main);
  padding: 8px;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
