.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-3);
  padding: var(--space-3);
}

.tokenPreview {
  text-align: center;
}

.tokenIcon {
  display: flex;
  justify-content: center;
}
