.card {
  border: none;
}

.select {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--color-border-light);
  height: 56px;
}

.select:hover,
.select:hover .networkSelect {
  border-color: var(--color-primary-main);
}

.networkSelect {
  border-left: 1px solid var(--color-border-light);
  padding: var(--space-2);
  margin-left: var(--space-2);
}
