.safeAppContainer {
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  border: 1px solid transparent;
}

.safeAppContainer:hover {
  background-color: var(--color-background-light);
  border: 1px solid var(--color-secondary-light);
}

.safeAppHeader {
  padding: var(--space-3) var(--space-2) 0 var(--space-2);
}

.safeAppContent {
  padding: var(--space-2);
}

.safeAppIconContainer {
  position: relative;
}

.safeAppIconContainer iframe {
  display: block;
}

.safeAppBatchIcon {
  position: absolute;
  top: -6px;
  right: -8px;
}

.safeAppTitle {
  line-height: 175%;
  margin: 0;

  flex-grow: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.safeAppDescription {
  /* Truncate Safe App Description (3 lines) */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.safeAppTagContainer {
  padding-top: var(--space-2);
}

.safeAppTagLabel {
  border-radius: 4px;
  height: 24px;
}

.safeAppTagLabel > * {
  padding: var(--space-1);
}
