.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
  padding: var(--space-3);
}

.imageWrapper {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
}

.imageWrapper img {
  display: block;
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  border-radius: 16px;
  position: relative;
  z-index: 2;
}

.imageWrapper img[src$='/ENS.png'] {
  height: 100px;
  width: 100px;
  background-color: var(--color-background-paper);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.wrapper a:last-child {
  margin-top: var(--space-3);
  line-height: 2;
}
