.tabs {
  overflow: initial;
}

/* Scroll buttons */
.tabs :global .MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.tabs :global .MuiTabScrollButton-root ~ .MuiTabs-scroller p {
  padding-bottom: 0;
}

.tabs :global .MuiTabScrollButton-root:first-of-type {
  margin-left: calc(var(--space-2) * -1);
}

.tabs :global .MuiTabScrollButton-root:last-of-type {
  margin-right: calc(var(--space-2) * -1);
}

.tab {
  opacity: 1;
  padding: 0 var(--space-3);
  position: relative;
  z-index: 2;
}

.label {
  text-transform: none;
  padding-bottom: 6px;
}
