.wrapper {
  width: 100%;
  height: calc(100vh - var(--header-height));
}

.iframe {
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  border: none;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
