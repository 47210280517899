.card {
  border: none;
}

.header {
  padding: var(--space-3) var(--space-2);
  border-bottom: 1px solid var(--color-border-light);
}

.header :global .MuiCardHeader-title {
  font-weight: 700;
}

.header :global .MuiCardHeader-subheader {
  color: var(--color-text-primary);
}

.step {
  background-color: var(--color-primary-main);
  height: 20px;
  width: 20px;
}

.content {
  padding: 0 !important;
}

.actions {
  padding: var(--space-3) 52px;
}

.progress :global .MuiLinearProgress-root::before {
  display: none;
}

@media (max-width: 600px) {
  .header {
    padding: var(--space-2);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-1);
  }
}
