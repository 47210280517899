.bell {
  display: flex;
  justify-content: center;
  padding: 4px;
}

.bellicon {
  width: 18px;
  height: 18px;
}

.popoverContainer {
  width: 446px;
  border: 1px solid var(--color-border-light);
}

.popoverHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-3);
  border-bottom: 2px solid var(--color-background-main);
}

.popoverFooter {
  padding: var(--space-2);
  display: flex;
  align-items: center;
}

.expandButton {
  box-sizing: border-box;
  background-color: var(--color-border-light);
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 18px;
  padding: 0;
}

.expandButton > * {
  color: var(--color-border-main);
}

.unreadCount {
  display: inline-block;
  background: var(--color-secondary-light);
  border-radius: 6px;
  margin-left: 9px;
  color: var(--color-static-main);
  text-align: center;
  width: 18px;
  height: 18px;
}
