.gridContainer {
  width: 100%;
  display: grid;
  gap: var(--space-2);
  align-items: center;
  white-space: nowrap;
}

.columnTemplate {
  grid-template-columns: minmax(50px, 0.25fr) minmax(150px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 1fr 1fr minmax(
      170px,
      1fr
    );
  grid-template-areas: 'nonce type info date confirmations actions status';
}

.columnTemplateWithoutNonce {
  grid-template-columns: minmax(50px, 0.25fr) minmax(150px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 1fr 1fr minmax(
      170px,
      1fr
    );
  grid-template-areas: 'nonce type info date confirmations actions status';
}

.columnTemplateTxHistory {
  grid-template-columns: minmax(50px, 0.25fr) minmax(150px, 2fr) minmax(150px, 2fr) minmax(100px, 1fr) minmax(
      170px,
      1fr
    );
  grid-template-areas: 'nonce type info date status';
}

.columnWrap {
  white-space: normal;
}

@media (max-width: 600px) {
  .columnWrap {
    word-break: break-word;
  }

  .gridContainer {
    gap: var(--space-1);
  }

  .columnTemplate {
    grid-template-columns: repeat(12, auto);
    grid-template-areas:
      'nonce type type type type type type type type type type type'
      'empty info info info info info info info info info info info'
      'empty date date date date date date date date date date date'
      'empty confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations'
      'empty status status status status status status status status status status status'
      'empty actions actions actions actions actions actions actions actions actions actions actions';
  }

  .columnTemplateWithoutNonce {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      'nonce type type type type type type type type type type type'
      'empty info info info info info info info info info info info'
      'empty date date date date date date date date date date date'
      'empty confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations confirmations'
      'empty status status status status status status status status status status status'
      'empty actions actions actions actions actions actions actions actions actions actions actions';
  }

  .columnTemplateTxHistory {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      'nonce type type type type type type type type type type type'
      'empty info info info info info info info info info info info'
      'empty date date date date date date date date date date date'
      'empty status status status status status status status status status status status';
  }
}
