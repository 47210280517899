.wrapper {
  width: 100%;
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content > * {
  margin-top: 10px;
}

.linkWrapper {
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
}

.linkWrapper > :first-of-type {
  margin-right: 5px;
}

.icon {
  position: relative;
  left: 3px;
  top: 3px;
}

.image {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 64px;
  height: 64px;
}
