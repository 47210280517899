.simulateAccordion :global .MuiAccordionSummary-content {
  justify-content: space-between;
  align-items: center;
}

.loadingText {
  margin-left: var(--space-1);
}

.result {
  border: none;
}

.skeletonWrapper {
  border-radius: 8px;
  overflow: hidden;
  margin-top: var(--space-2);
}
