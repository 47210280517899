.container {
  background-color: var(--color-secondary-background);
  border: 1px solid var(--color-secondary-main);
  padding: var(--space-1);
  border-radius: 4px;
  margin-top: var(--space-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
