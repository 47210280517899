.info {
  background-color: var(--color-background-main);
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: var(--space-1);
}

.overline {
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1px;
  align-self: center;
  text-transform: uppercase;
}
