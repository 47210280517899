.accordion {
  background: var(--color-background-lightcolor);
  border-radius: 10px !important;
}

.accordion * {
  border-width: 1px;
}

.closed:hover,
.batched {
  background-color: var(--color-secondary-background);
  border-color: var(--color-secondary-light);
}

.expanded {
  border-color: var(--color-secondary-light) !important;
}

.expanded :global .MuiAccordionSummary-root {
  background-color: var(--color-secondary-background);
}
